<template>
  <div class="bg-grey">
    <ApplicantHeader />
    <div class="container-fluid job-listings px-1 px-md-3">
      <div class="main-heading">
        <h4 class="font-weight-bold mb-0">JOBS</h4>
      </div>
      <b-tabs align="right" class="mt-n5 applicant-jobs-tab">
        <b-tab title="All Jobs">
          <!-- <div class="job-search-bar mt-4">
            <div class="selector-wrapper job-selector-wrapper search-block">
              <input
                type="text"
                class="form-control"
                placeholder="Search for jobs"
                v-model="search"
              />
            </div>
            <div
              class="selector-wrapper location-selector-wrapper location-block custom-select-2"
            >
              <multiselect
                v-model="selectedCountry"
                placeholder="Select Country"
                label="checkbox"
                track-by="checkbox_id"
                :options="countries"
                @input="onSelectCountry"
                :multiple="false"
              />
            </div>
            <div class="search-button">
              <button
                class="btn btn-job-search w-100"
                @click.prevent.stop="getAllJobs"
              >
                SEARCH
              </button>
            </div>
          </div> -->
          <!--End Job Search Bar-->

          <GetAllJobs />
        </b-tab>
        <!--End All Jobs Tab-->
        <b-tab title="Applied Jobs">
          <div class="job-search-bar mt-4">
            <div class="selector-wrapper job-selector-wrapper search-block">
              <input type="text" class="form-control" placeholder="Search for jobs" v-model="search" />
            </div>
            <div class="selector-wrapper location-selector-wrapper location-block custom-select-2">
              <multiselect v-model="selectedCountry" placeholder="Select Country" label="checkbox" track-by="checkbox_id"
                :options="countries" @input="onSelectCountry" :multiple="false" />
            </div>
            <div class="search-button">
              <button class="btn btn-job-search w-100" @click.prevent.stop="getAppliedJobs">SEARCH</button>
            </div>
          </div>
          <!--End Job Search Bar-->

          <div class="job-sort-bar py-2 row mt-4 mb-3 mx-0">
            <div class="col-md-10 left">
              <div class="d-sm-none mobile-filter">
                <button class="btn btn-mobile-filter mb-3" @click="showFilters1"><b-icon icon="funnel-fill"
                    @click="showFilters1" class="mobile-filter-icon" width="20px"></b-icon>FILTERS</button>
              </div>
            </div>
            <!-- <div class="col-md-2 right">
              <div>
                <img
                  src="@/assets/app-icons/sort-amount-down-alt-solid.svg"
                  alt=""
                  height="16px"
                  width="16px"
                />
                Sort By
              </div>
              <div>
                <v-select
                  class="form-control"
                  :options="sortingOptions"
                  placeholder="Default"
                  @input="sortJobs"
                >
                </v-select>
              </div>
            </div> -->
          </div>
          <!--End Job Sort Bar-->

          <div class="row mx-0 mt-xl-5">
            <div class="col-md-3 px-0">
              <div class="col-filter-box" :class="{ isMobileFilter1 }">
                <div class="text-right d-sm-none">
                  <svg @click="hideFilters1" width="3rem" height="3rem" viewBox="0 0 16 16" class="text-body bi bi-x"
                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
                <div class="sub-heading">
                  <h5 class="mb-0">Filters</h5>
                </div>
                <div v-for="(data, index) in filters" :key="index">
                  <div class="col-filter-collapse collapse-card" v-if="data.title != 'Shortlist'">
                    <div class="filter-tab-title" v-b-toggle="'collapse-' + index">
                      {{ data.title }}
                    </div>
                    <b-collapse :id="'collapse-' + index" class="pt-2">
                      <div>
                        <div class="flex-between" v-for="(check, i) in data.checkbox" :key="i">
                          <span class="filter-tab-text">{{ check.checkbox }} </span>
                          <b-form-checkbox v-model="appliedFilter.filters" :value="check"> </b-form-checkbox>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
                <div class="flex-between col-filter-btn">
                  <button type="button" class="btn btn-reset" @click="resetFilters">RESET</button>
                  <button type="button" class="btn btn-apply" @click="getAppliedJobs">APPLY</button>
                </div>
              </div>
            </div>
            <!--End Col Filter Box--->
            <div class="col-md-12 col-lg-9 px-0 px-md-3">
              <b-modal ref="myModal" hide-header hide-footer>
                <div class="text-center">
                  <b-spinner label="Loading..." style="width: 8rem; height: 8rem"> </b-spinner>
                  <h2>Please Wait Job Is Loading...</h2>
                </div>
              </b-modal>
              <div class="not-found-div" v-if="appliedJobs.length == 0">
                <h1>You have not applied for any job</h1>
                <!-- <h4>Apply for the job to get the result</h4> -->
              </div>
              <div class="job-post-card" v-for="(job, index) in appliedJobs" :key="index">
                <div class="job-post-header">
                  <div class="left">
                    <div style="cursor: pointer" @click="() =>
                      $router.push({
                        name: 'Job-Details',
                        params: { id: job.job_id },
                      })
                      ">
                      <img :src="job.company_logo_path" width="50" />
                    </div>
                    <div style="cursor: pointer" @click="() =>
                      $router.push({
                        name: 'Job-Details',
                        params: { id: job.job_id },
                      })
                      ">
                      <span>{{ job.company_long_name }}</span>
                    </div>
                    <div class="d-flex align-items-center">
                      <img src="@/assets/app-icons/clock.svg" width="17px" />
                      <span class="pl-1"> {{ job.apply_at }}d ago (applied)</span>
                    </div>
                  </div>
                </div>
                <div class="job-post-details">
                  <h5 class="job-title mb-0" style="text-transform: capitalize">
                    {{ job.job_title }}
                  </h5>
                  <div>
                    <h6 class="heading">Salary (Annually)</h6>
                    <div class="d-flex">
                      <div class="mr-2">{{ job.currency_code }}</div>
                      <div class="pr-2">{{ formattedNumber(job.job_salary_range_from) }}</div>

                      <div class="pr-2">-</div>
                      <div class="mr-2">{{ job.currency_code }}</div>
                      <div>{{ formattedNumber(job.job_salary_range_to) }}</div>
                    </div>
                  </div>
                  <div>
                    <h6 class="heading">Job Type</h6>
                    <p>{{ job.job_type }}</p>
                  </div>
                  <div>
                    <h6 class="heading">Location</h6>
                    <div class="d-flex job-location">
                      <p v-for="c in job.city" :key="c.city_id">
                        {{ c.city_name }}
                      </p>
                    </div>
                  </div>
                  <div>
                    <!-- <button
                      class="btn btn-success float-right"
                      :disabled="true"
                    >
                      {{ job.match }}% Match &nbsp;<img
                        src="@/assets/app-icons/question-mark.svg"
                      />
                    </button> -->
                  </div>
                </div>
                <div class="job-post-description">
                  <h6 class="desc-title">Description</h6>
                  <p class="desc-content mb-0">
                    {{ job.job_description }}
                  </p>
                </div>
                <div class="job-post-footer">
                  <!-- <div class="left">
                    <div
                      class="share-text mr-4"
                      v-clipboard="
                        () => `${SERVERURL}job-details/${job.job_id}`
                      "
                      @success="handleSuccess"
                      @error="handleError"
                      style="cursor: pointer"
                    >
                      <span> SHARE </span>
                      <img
                        src="@/assets/app-icons/share.svg"
                        width="16px"
                        class="ml-2 mb-0"
                      />
                    </div>
                    <div
                      class="share-text"
                      @click="AddBookmark(job, index)"
                      style="cursor: pointer"
                    >
                      <span> SHORTLIST </span>
                      <img
                        v-if="!job.bookmark"
                        src="@/assets/app-icons/bookmark.svg"
                        width="15px"
                        class="ml-2"
                      />
                      <img
                        v-if="job.bookmark"
                        src="@/assets/app-icons/yellow-bookmark.svg"
                        width="15px"
                        class="ml-2"
                      />
                    </div>
                  </div> -->
                  <div class="right ml-auto">
                    <button class="btn btn-job-details btn-primary"
                      v-show="($cookies.get('role_id') == 3 || !$cookies.get('role_id')) && !job.applied" @click="
                        $router.push({
                          name: 'Applicant-Answer',
                          params: { job_id: job.job_id },
                        })
                        ">
                      VIEW APPLICATION
                    </button>
                    <button class="btn btn-apply"
                      v-show="($cookies.get('role_id') == 3 || !$cookies.get('role_id')) && job.applied" @click="
                        $router.push({
                          name: 'Job-Application',
                          params: { job_id: job.job_id },
                        })
                        ">
                      APPLY
                    </button>
                    <div>
                      <span class="dot"
                        :class="job.emp_status && job.emp_status === 'viewed' ? 'background-yellow' : job.emp_status === 'shortlisted' || job.emp_status === 'appointed' ? 'background' : ''"></span>
                      <span class="dot"
                        :class="job.emp_status && (job.emp_status === 'shortlisted' || job.emp_status === 'appointed') ? 'background' : ''"></span>
                      <!-- <span
                        class="dot"
                        v-if="job.application_status == 'Applied'"
                        :class="
                          job.emp_status && job.emp_status === 'appointed'
                            ? 'background'
                            : ''
                        "
                      ></span> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-between jobs-navigation pb-3">
                <div>
                  <button :disabled="pagination.current == 1" class="btn btn-back" @click="previousPage">Back</button>
                </div>
                <div>
                  <button :disabled="pagination.current * pagination.pageSize > totalCountForAppliedJobs"
                    class="btn btn-next" @click="nextPage">Next</button>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <!--End Applied Jobs-->
      </b-tabs>
    </div>
    <!-- @show="resetModal" -->
    <b-modal id="share-modal" ref="modal" @hidden="resetModal" @ok="handleOk" hide-footer>
      <div>
        <h4>Share this job on social network</h4>
        <div class="social-icons-grid">
          <ShareNetwork network="Whatsapp" :url="`${SERVERURL}job-details/${selectedJob.job_id}`"
            :title="selectedJob.job_title ? selectedJob.job_title : ''"
            :description="selectedJob.job_description ? selectedJob.job_description : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-whatsapp"></i>
            </div>
          </ShareNetwork>
          <ShareNetwork network="Facebook" :url="`${SERVERURL}job-details/${selectedJob.job_id}`"
            :title="selectedJob.job_title ? selectedJob.job_title : ''"
            :description="selectedJob.job_description ? selectedJob.job_description : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-facebook"></i>
            </div>
          </ShareNetwork>
          <ShareNetwork network="Twitter" :url="`${SERVERURL}job-details/${selectedJob.job_id}`"
            :title="selectedJob.job_title ? selectedJob.job_title : ''"
            :description="selectedJob.job_description ? selectedJob.job_description : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-twitter"></i>
            </div>
          </ShareNetwork>
          <ShareNetwork network="Linkedin" :url="`${SERVERURL}job-details/${selectedJob.job_id}`"
            :title="selectedJob.job_title ? selectedJob.job_title : ''"
            :description="selectedJob.job_description ? selectedJob.job_description : ''" @open="handleSubmit">
            <div>
              <i class="fa fa-linkedin"></i>
            </div>
          </ShareNetwork>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import ApplicantJobService from "@/apiServices/ApplicantJobServices";
import ApplicantProfileService from "@/apiServices/ApplicantProfileService";
import LocationService from "@/apiServices/LocationServices";
import FilterService from "@/apiServices/FilterService";
import NavigatorShare from "vue-navigator-share";

import ApplyJobService from "@/apiServices/ApplyJobServices";
import JobAppliedSuccess from "@/views/Job-applied-successfully.vue";
import ApplicantHeader from "@/views/Applicant-Profile-Screens/Applicant.vue";
import GetAllJobs from "../jobs/GetAllJobs.vue";
import { SERVERURL } from "@/config";
import moment from "moment";
import Multiselect from "vue-multiselect";
import numeral from "numeral";

export default {
  components: {
    JobAppliedSuccess,
    ApplicantHeader,
    NavigatorShare,
    Multiselect,
    GetAllJobs,
  },
  data() {
    return {
      isMobileFilter: window.innerWidth <= 1600,
      isMobileFilter1: window.innerWidth <= 1600,
      sort: { label: "Date" },
      loading: false,
      selectedJob: {},
      jobs: [],
      totalCount: 0,
      totalCountForAppliedJobs: 0,
      appliedJobs: [],
      copiedJobLink: null,
      wfh: null,
      SERVERURL: SERVERURL,
      city: null,
      search: "",
      selectedCity: null,
      days: [],
      appDetails: null,
      cities: [],
      countries: [],
      selectedCountry: [],
      match: null,
      bookmarkFilter: false,
      sortOptions: [{ label: "Name" }, { label: "Date" }],
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        searchText: "",
        feature: "Y",
        city_id: null,
        role_id: this.$cookies.get("role_id"),
        user_id: this.$cookies.get("user_id"),
      },
      appliedFilter: { filters: [] },
      isMobileFilter: window.innerWidth <= 700,
      selected: [],
      filters: [
        { title: "Work From Home Jobs", checked: false },
        { title: "Country", checkbox: [] },
        { title: "City", checkbox: [] },
        { title: "Speciality", checkbox: [] },
        {
          title: "Employment type",
          checkbox: [
            {
              title: "job_type",
              checkbox: "Full Time",
              checkbox_id: "Full Time",
            },
            {
              title: "job_type",
              checkbox: "Part Time",
              checkbox_id: "Part Time",
            },
            { title: "job_type", checkbox: "Remote", checkbox_id: "Remote" },
            {
              title: "job_type",
              checkbox: "Internship",
              checkbox_id: "Internship",
            },
            {
              title: "job_type",
              checkbox: "Contract",
              checkbox_id: "Contract",
            },
          ],
        },
        { title: "Company Type", checkbox: [] },
        {
          title: "Salary Range",
          checkbox: [
            {
              title: "salary",
              checkbox: "100 - 500",
              checkbox_id: { min: 100, max: 500 },
            },
            {
              title: "salary",
              checkbox: "500 - 10000",
              checkbox_id: { min: 500, max: 10000 },
            },
            {
              title: "salary",
              checkbox: "10000 - 20000",
              checkbox_id: { min: 10000, max: 20000 },
            },
          ],
        },
        { title: "Shortlist", checked: false },
      ],
    };
  },
  computed: {
    url() {
      return window.location.href;
    },
    title() {
      return "Job Details";
    },
  },
  created() {
    addEventListener("resize", () => {
      this.isMobileFilter = innerWidth <= 700;
      this.isMobileFilter1 = innerWidth <= 700;
    });
  },
  beforeMount() {
    this.getCountries();
  },
  mounted: function () {
    this.copiedJobLink = `${SERVERURL}job-details/`;
    this.getIndustry();
    //this.getSkills();
    //  this.getCities(this.pagination);

    this.getApplicantDetails();
    // this.showModal();
  },
  methods: {
    formattedNumber(sal) {
      //console.log(sal);

      return numeral(sal).format('0,0');
    },
    removeFromFilter() {
      console.log("in remove :>> ");
      this.appliedFilter.filters.map((x, index) => {
        if (x.title == "City2") {
          this.appliedFilter.filters.splice(index, 1);
        }
      });
      console.log("this.appliedFilter.filters :>> ", this.appliedFilter.filters);
    },
    onSelectCountry() {
      const payload = [this.selectedCountry];
      console.log("payload ====:>> ", payload);
      this.appliedFilter.filters = payload[0] ? payload : [];
      console.log("this.appliedFilter.filters =====:>> ", this.appliedFilter.filters);
      this.onChangeCheckbox(this.appliedFilter.filters);
    },
    showFilters: function () {
      document.querySelector(".isMobileFilter").classList.add("show-filter");
      document.querySelector(".navbar").classList.add("navbar-none");
    },
    hideFilters: function () {
      document.querySelector(".isMobileFilter").classList.remove("show-filter");
      document.querySelector(".navbar").classList.remove("navbar-none");
    },
    showFilters1: function () {
      document.querySelector(".isMobileFilter1").classList.add("show-filter1");
      document.querySelector(".navbar").classList.add("navbar-none");
    },
    hideFilters1: function () {
      document.querySelector(".isMobileFilter1").classList.remove("show-filter1");
      document.querySelector(".navbar").classList.remove("navbar-none");
    },
    onError(err) {
      console.log(err);
    },
    onSuccess(err) {
      console.log(err);
    },
    resetModal() {
      this.selectedJob = {};
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      // if (!this.checkFormValidity()) {
      //   return;
      // }
      // Push the name to submitted names
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("share-modal");
      });
    },
    getAllWfhJobs() { },
    share(job) {
      console.log(`job`, job);
      this.selectedJob = job;
      console.log(`this.selectedJob`, this.selectedJob);
      this.$forceUpdate();
      this.$bvModal.show("share-modal");
    },
    showModal() {
      this.$refs["myModal"].show();
    },
    hideModal() {
      this.$refs["myModal"].hide();
    },
    getApplicantDetails() {
      const applicant_id = this.$cookies.get("user_id");
      ApplicantProfileService.getApplicantProfile({ applicant_id }).then((res) => {
        this.appDetails = res.data.data.user_data[0];
        this.appDetails.skills = this.appDetails.skills.map(({ skill_name }) => {
          return skill_name;
        });
        this.appDetails.education = res.data.data.user_education.map((edu) => {
          return { degree: edu.education_degree };
        });
        this.getAllJobs(this.pagination);
      });
    },
    async onChangeCheckbox(e) {
      console.log("hiting", e);
      let payload = e;
      const lastElement = payload[payload.length - 1];
      console.log("lastElement :>> ", lastElement);

      if (!payload.some((s) => s.title === "Country")) {
        console.log("Country not present");
        this.cities = [];
        this.filters[2].checkbox = null;
      }
      payload = payload.map((m) => m.checkbox_id);
      if (lastElement && lastElement.title == "Country") {
        let loader = this.$loading.show();
        try {
          const city = await LocationService.GetAllCityOfCountry(payload);
          this.cities = city.data.data;
          this.filters[2].checkbox = this.cities.map((m) => {
            return {
              title: "City",
              checkbox: m.city_name,
              checkbox_id: m.city_id,
            };
          });
          loader.hide();
        } catch (error) {
          loader.hide();

        }
      }

      //  let loader = this.$loading.show();
      // try {
      //   const city = await LocationService.GetAllCities(payload);
      //   
      //   // this.cities = city.data.data;
      //   // const c = await FilterService.GetAllCities();
      //   // this.filters[2].checkbox = c.data.data;
      //   loader.hide();
      // } catch (error) {
      //   
      //   loader.hide();
      // }
    },
    getAllJobs(paginate = {}) {
      this.getCountries();
      if (Object.keys(paginate).length === 0 && paginate.constructor === Object) {
        this.pagination.current = 1;
        this.pagination.pageSize = 10;
      }

      // Object.assign(payload, this.appliedFilter);
      if (this.selectedCity) {
        let index = this.appliedFilter.filters.findIndex((x) => x.title == "City2");

        console.log("index:::: :>> ", index);

        if (index < 0) {
          this.appliedFilter.filters.push({
            city_name: this.selectedCity.city_name,
            city_id: this.selectedCity.city_id,
            title: "City2",
          });
        } else {
          this.appliedFilter.filters[index].city_name = this.selectedCity.city_name;
          this.appliedFilter.filters[index].city_id = this.selectedCity.city_id;
        }
      }
      const wfh = this.wfh;
      console.log("this.appliedFilters :>> ", this.appliedFilter);
      let loader = this.$loading.show();
      ApplicantJobService.GetAllJobs(
        {
          current: 1,
          pageSize: 10,

          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          searchText: this.search,
          feature: "Y",
          role_id: this.$cookies.get("role_id"),
          user_id: this.$cookies.get("user_id"),
          ...paginate,
        },
        { filters: this.appliedFilter.filters },
        {
          wfh,
        }
      )
        .then(async (res) => {
          // const data = await res.data.data.forEach((job, index) => {
          //   if (job.job_active === "Y") {
          //     job.applied = true;
          //     job.bookmark = false;
          //     this.jobs.push(job);
          //   }
          // });
          this.loading = true;
          loader.hide();
          console.log(" res.data.data 111111111:>> ", res.data.data);
          this.jobs = res.data.data.data;
          this.totalCount = res.data.data.totalCount;
          this.jobs = this.jobs.map((m) => {
            return {
              ...m,
              deadline: moment(m.job_end_date).format("DD/MM/YYYY"),
            };
          });
          if (this.search != "" || (this.appliedFilter && this.appliedFilter.filters.length)) {
            let jobContries = res.data.data.job_countries;
            console.log("this.countries ====--:>> ", this.countries);
            this.countries = this.countries.filter((f) => {
              const valid = jobContries.some((s) => s == f.checkbox_id);
              console.log("valid :>> ", valid);
              if (valid) {
                return true;
              }
            });
            this.filters[1].checkbox = this.countries;
          }
          this.getAppliedJobs();
          this.daysAgo();
          this.getAllBookmark();
        })
        .catch((error) => {

          this.loading = true;
          loader.hide();
        });
    },
    nextPage() {
      this.pagination.current = this.pagination.current + 1;
      this.jobs = [];
      this.getAllJobs(this.pagination);
      this.sort = { label: "Date" };
    },
    previousPage() {
      this.pagination.current = this.pagination.current - 1;
      this.jobs = [];
      this.getAllJobs(this.pagination);
      this.sort = { label: "Date" };
    },

    getAllBookmarkJobs() {
      const user_id = this.$cookies.get("user_id");
      console.log("this.bookmarkFilter :>> ", this.bookmarkFilter);
      if (!this.bookmarkFilter) {
        console.log("hitting bookmark");
        let loader = this.$loading.show();

        this.jobs = [];
        ApplicantJobService.GetBookmark({ user_id })
          .then(async (res) => {
            const data = await res.data.data.forEach((job, index) => {
              if (job.job_active === "Y") {
                // job.applied = true;
                job.bookmark = true;
                this.jobs.push(job);
              }
            });

            this.jobs = this.jobs.map((m) => {
              return {
                ...m,
                deadline: moment(m.job_end_date).format("DD/MM/YYYY"),
              };
            });
            loader.hide();
            this.getAppliedJobs();
            this.daysAgo();
          })
          .catch((err) => {

            loader.hide();
          });
      } else {
        this.jobs = [];
        this.getAllJobs(this.pagination);
      }
    },
    getFilteredAppliedJobs() {
      this.appliedFilter.searchText = this.search;
      this.appliedFilter.user_id = this.$cookies.get("user_id");
      this.appliedFilter.city_id = this.selectedCity?.city_id;
      this.appliedJobs = [];

      ApplicantJobService.GetFilteredAppliedJobs(this.appliedFilter).then(async (res) => {
        this.appliedJobs = res.data.data;
        this.getAllBookmark();
      });
    },
    getCities(payload) {
      // LocationService.GetAllCities(payload).then((city) => {
      //   this.cities = city.data.data;
      //   this.filters[1].checkbox = city.data.data.map((city) => {
      //     return {
      //       title: "City",
      //       checkbox: city.city_name,
      //       checkbox_id: city.city_id,
      //     };
      //   });
      // });
    },
    getCountries() {
      // LocationService.GetAllCountry().then((res) => {
      //   
      //   this.countries = res.data.data;
      //   this.filters[1].checkbox = res.data.data.map(
      //     ({ country_name, country_id }) => {
      //       return {
      //         title: "Country",
      //         checkbox: country_name,
      //         checkbox_id: country_id,
      //       };
      //     }
      //   );
      // });
      let loader = this.$loading.show();
      const role_id = this.$cookies.get("role_id");
      FilterService.GetAllCountries({ role_id })
        .then((res) => {

          this.countries = res.data.data;
          this.filters[1].checkbox = res.data.data;

          loader.hide();
        })
        .catch((err) => {

          loader.hide();
        });
    },

    AddBookmark(job, index) {
      const user_id = this.$cookies.get("user_id");
      const job_id = job.job_id;
      ApplicantJobService.AddBookmark({ user_id, job_id })
        .then((res) => {

          if (res.data.data.bookmark_active == "Y") {
            this.jobs[index].bookmark = true;
          } else {
            this.jobs[index].bookmark = false;
            this.getJobsBookMarkv2();
          }
        })
        .catch((err) => {

          console.log("job :>> ", job.bookmark_id);
          // this.getSingleBookmark(job.bookmark_id);
          //  this.jobs[index].bookmark = false;
        });
    },
    handleSuccess(e) {
      console.log("success", e);
      this.copiedJobLink = `${SERVERURL}job-details/${e}`;
      console.log("this.copiedJobLink :>> ", this.copiedJobLink);
      this.$toasted.show("Copied to clipboard", {
        duration: 6000,
        icon: "check",
      });
    },
    handleError(e) {
      console.log("failed", e);
    },

    getJobsBookMarkv2() {
      const user_id = this.$cookies.get("user_id");
      console.log("this.bookmarkFilter :>> ", this.bookmarkFilter);
      if (this.bookmarkFilter) {
        console.log("hitting bookmark");
        let loader = this.$loading.show();
        this.jobs = [];
        ApplicantJobService.GetBookmark({ user_id })
          .then(async (res) => {
            const data = await res.data.data.forEach((job, index) => {
              if (job.job_active === "Y") {
                // job.applied = true;
                job.bookmark = true;
                this.jobs.push(job);
              }
            });

            this.jobs = this.jobs.map((m) => {
              return {
                ...m,
                deadline: moment(m.job_end_date).format("DD/MM/YYYY"),
              };
            });
            loader.hide();
            this.getAppliedJobs();
            this.daysAgo();
          })
          .catch((err) => {

            loader.hide();
          });
      }
      // else {
      //   this.jobs = [];
      //   this.getAllJobs(this.pagination);
      //   this.hideModal();
      // }
    },
    getAllBookmark() {
      const user_id = this.$cookies.get("user_id");
      ApplicantJobService.GetBookmark({ user_id }).then((res) => {
        const bm = res.data.data;
        console.log("res.data.data ::::::>> ", res.data.data);
        this.jobs.forEach((job, index) => {
          bm.forEach((b) => {
            if (job.job_id == b.job_id) {
              this.jobs[index].bookmark_id = b.bookmark_id;
              this.jobs[index].bookmark = true;
            } else {
              //console.log("hitting :>> ", job.job_id == b.job_id);
              //this.jobs[index].bookmark = false;
            }
          });
        });
      });
    },
    getQuery() {
      this.pagination.searchText = this.search;
      this.pagination.city_id = this.selectedCity?.city_id;

      this.jobs = [];
      ApplicantJobService.GetFilteredJobs(this.pagination).then(async (res) => {
        this.jobs = [];
        const data = await res.data.data.forEach((job, index) => {
          if (job.job_active === "Y") {
            //job.applied = true;
            this.jobs.push(job);
            this.getAppliedJobs();
          }
        });
        this.getAllBookmark();
      });
    },
    sortJobs(e) {
      if (this.sort?.label == "Name") {
        const sorting = function (a, b) {
          if (a.job_title < b.job_title) {
            return -1;
          }
          if (a.job_title > b.job_title) {
            return 1;
          }
          return 0;
        };
        this.jobs.sort(sorting);
      }
      if (this.sort?.label == "Date") {
        this.getAllJobs(this.pagination);
        // const sortingDate = function (a, b) {
        //   let date1 = moment(a.job_end_date);
        //   let date2 = moment(b.job_end_date);
        //   let diff = date2.diff(date1);
        //   if (diff < 0) {
        //     return 1;
        //   } else {
        //     return -1;
        //   }

        //   return 0;
        // };
        // this.jobs.sort(sortingDate);
      }
    },
    getAppliedJobs() {
      let payload = this.pagination;
      payload.city_id = this.selectedCity ? this.selectedCity.city_id : null;
      payload.search = this.search;

      let filters = this.appliedFilter.filters;
      console.log("filters :>> ", filters);
      payload = { ...payload, filters };
      ApplyJobService.GetAllAppliedJobs(payload).then((res) => {
        this.appliedJobs = res.data.data.data;
        this.appliedJobs = this.appliedJobs.map((m) => {
          const now = moment();
          const apply = moment(m.apply_at);
          const diff = now.diff(apply, "days");
          return {
            ...m,
            apply_at: diff,
          };
        });
        this.totalCountForAppliedJobs = res.data.data.totalCount;
        // this.appliedJobs.forEach((aJob, index) => {
        //   this.jobs.forEach((j, i) => {
        //     if (j.job_id == aJob.job_id) {
        //       this.jobs[i].applied = false;
        //     }
        //   });
        // });
      });
    },
    getSkills() {
      ApplicantProfileService.getAllSkills().then((skill) => {
        console.log("skill :>> ", skill);
        // this.filters[2].checkbox = skill.data.data.map((s) => {
        //   return {
        //     title: "skills",
        //     checkbox: s.skill_name,
        //     checkbox_id: s.skill_id,
        //   };
        // });
      });
    },
    getIndustry() {
      ApplicantProfileService.getAllIndustry().then((i) => {
        this.filters[5].checkbox = i.data.data.map((ind) => {
          return {
            title: "industry",
            checkbox: ind.industry_name,
            checkbox_id: ind.industry_id,
          };
        });
      });
    },
    resetFilters() {
      this.appliedFilter = { filters: [] };
      this.bookmarkFilter = false;
    },
    appliedFilters() {
      console.log("this.appliedFilter :>> ", this.appliedFilter);
      this.bookmarkFilter = false;
      this.appliedFilter.user_id = this.$cookies.get("user_id");
      this.appliedFilter.role_id = this.$cookies.get("role_id");
      Object.assign(this.appliedFilter, this.pagination);
      ApplicantJobService.GetAllJobs(this.appliedFilter).then(async (res) => {

        this.jobs = [];
        this.totalCount = res.data.data.totalCount;
        const data = await res.data.data.data.forEach((job, index) => {
          if (job.job_active === "Y") {
            //   job.applied = true;
            this.jobs.push(job);
            this.getAppliedJobs();
            this.getAllBookmark();
          }
        });
      });
      // ApplicantJobService.GetFilteredJobs(this.appliedFilter).then(
      //   async (res) => {
      //     this.jobs = [];
      //     const data = await res.data.data.forEach((job, index) => {
      //       if (job.job_active === "Y") {
      //         job.applied = true;
      //         this.jobs.push(job);
      //         this.getAppliedJobs();
      //         this.getAllBookmark();
      //       }
      //     });
      //   }
      // );
    },
    daysAgo() {
      this.jobs.forEach((job) => {
        let now = moment().format("YYYY-MM-DD");
        let jobCreated = moment(job.job_start_date);
        let diff = jobCreated.diff(now, "day") * -1;
        this.days.push(diff);
      });
    },
    applyJob(company_id, job_id, index) {
      const user_id = this.$cookies.get("user_id");
      const payload = {
        user_id: user_id,
        job_id: job_id,
        company_id: company_id,
      };
      ApplyJobService.ApplyJob(payload)
        .then((res) => {
          // this.$bvModal.show("successPage");
          this.jobs[index].applied = false;
          this.$toasted.show("Job Applied Successfully!", {
            position: "top-right",
            duration: 6000,
            icon: "check",
          });
          this.getAppliedJobs();
          //this.$router.push({ name: "Job-applied-successfully" });
        })
        .catch((err) => {

          this.jobs[index].applied = false;
          this.$toasted.show("You have already applied for this job", {
            position: "top-right",
            duration: 6000,
            icon: "clear",
          });
        });
    },
    showFilters: function () {
      document.querySelector(".isMobileFilter").classList.add("show-filter");
      document.querySelector(".navbar").classList.add("navbar-none");
    },
    hideFilters: function () {
      document.querySelector(".isMobileFilter").classList.remove("show-filter");
      document.querySelector(".navbar").classList.remove("navbar-none");
    },
  },
};
</script>

<style>
.job-modal .modal-footer div {
  display: flex;
}

.job-modal .modal-footer {
  justify-content: center;
}

.job-modal .modal-header>div:first-child {
  display: flex !important;
  flex-direction: row !important;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.app-job .share-text {
  align-items: start;
}

.applicant-jobs-tab .nav-tabs {
  border: 1px solid transparent !important;
}

.applicant-jobs-tab .nav-tabs .nav-link {
  color: #b1b1b1;
}

.applicant-jobs-tab .nav-tabs .nav-link.active {
  color: #315d9b !important;
  border-bottom: 6px solid #315d9b !important;
}

.dot {
  height: 25px;
  width: 25px;
  margin-left: 10px;
  border: 1px solid black;
  border-radius: 50%;
  display: inline-block;
}

.background {
  background-color: green;
}

.background-yellow {
  background: yellow;
}
</style>
